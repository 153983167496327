import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 910px;
  background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top -60px left;
  @media ${devices.xs} {
    min-height: 660px;
  }

  .logo {
    margin-top: 50px;
    position: relative;
    margin-bottom: 90px;
  }

  .selo {
    position: absolute;
    right: 28%;
    top: 9%;

    @media ${devices.xs} {
      right: 7%;
    }

    @media ${devices.sm} {
      right: 15%;
    }

    @media ${devices.md} {
      right: 20%;
    }

    @media ${devices.lg} {
      right: 28%;
    }
  }

  .imageSelo {
    @media ${devices.xs} {
      display: none;
    }
  }

  .sub-title {
    color: ${({ titleColor }) => titleColor};
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }

  .button {
    margin-bottom: 50px;
  }

  .wrapper-button-i18n {
    position: absolute;
    width: 100%;
    z-index: 1;

    @media ${devices.xs} {
      top: 32px;
    }
    @media ${devices.sm} {
      top: 32px;
    }
    @media ${devices.md} {
      top: 50px;
    }
  }
`;

export const Color = styled.div`
  background-color: ${({ color }) => color};
  height: 13px;
  flex: 1;
`;