import i18n_translations from "../../components/PropagoCheckout/i18n/translations"

console.log(i18n_translations["pt-BR"].translations)

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Rumo ao Futuro Brilhante GAMT - Educação, Inclusão e Oportunidades Transformadoras",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00002: {
        title: "GRUPO DE ASSESSORIA E MOBILIZAÇÃO DE TALENTOS  GAMT",
        subtitle:
          "Transformamos sonhos em oportunidades, moldando o futuro com educação e inclusão.",
        button: "Saiba mais",
      },
      COMP00004: {
        title: "NOSSOS NÚMEROS",
        cards: [
          {
            top: "MAIS DE",
            span: "30",
            bottom: "PROJETOS REALIZADOS",
          },
          {
            top: "MAIS DE",
            span: "300",
            bottom: "EMPRESAS BENEFICIADAS",
          },
          {
            top: "MAIS DE",
            span: "4.500",
            bottom: "JOVENS INSERIDOS NO MERCADO",
          },
        ],
        button: "FAÇA PARTE DA TRANSFORMAÇÃO: DOE!",
      },
      COMP00005: {
        title: "DIRETRIZES DO ORGANIZAÇÃO",
        subTitle: "",
        cards: [
          {
            title: "Missão",
            text: "Oferecer oportunidades de desenvolvimento pessoal e profissional para a inserção de jovens no mercado de trabalho, por meio de educação e valorização da cidadania.",
          },
          {
            title: "Visão",
            text: "Ser a referência nacional na transformação social de jovens.",
          },
        ],
        button: "DOE AGORA.",
      },
      COMP00006: {
        title: "",
        cards: [
          {
            title: "<strong>Conheça o Potencial no GAMT LAB!</strong>",
            text: `
            <p>GAMT LAB, onde transformamos crianças e adolescentes de 6 a 15 anos em verdadeiros protagonistas do seu próprio aprendizado! Aqui, as atividades vão além das salas de aula tradicionais, proporcionando experiências práticas e estimulando o desenvolvimento de habilidades essenciais para o mundo do trabalho.</p>
            <p>Em nossas aulas práticas, mergulhamos no universo da tecnologia, criatividade e trabalho em equipe. Desafiamos os participantes a pensarem fora da caixa, a resolverem problemas complexos e a explorarem novas formas de expressão criativa. O objetivo? Preparar nossos alunos para os empregos do futuro, capacitando-os a aprender sempre.</p>
            `,
          },
          {
            title: "<strong>Crianças e jovens como Protagonistas no mundo  da Tecnologia</strong>",
            text:
              '"No Fab Lab GAMT, os participantes têm a oportunidade única de criar protótipos usando materiais recicláveis, impressoras 3D e outras ferramentas incríveis. Aprender programação torna-se uma jornada envolvente, utilizando linguagens visuais e intuitivas para desenvolver habilidades fundamentais de forma acessível e divertida."',
          },
          {
            title:
              "<strong>Mapa de Oportunidades : Preparação para o Mercado de Trabalho</strong>",
            text:
              `
              <p>Jovens de 16 a 19 anos têm a oportunidade de participar do Programa Mapa de Oportunidades, uma formação híbrida e dinâmica que os coloca como protagonistas de sua própria jornada profissional. Ao completar o curso, o jovem  se tornará parte do nosso Banco de Talentos, abrindo portas para oportunidades de aprendizagem e estágio em empresas parceiras.</p>`,
          },
        ],
        appeal: "Educação que inspira, inclusão que transforma.",
        button: "DOE AGORA E FAÇA A DIFERENÇA!",
      },
      COMP00008: {
        title: "SEJA A PONTE PARA UM FUTURO TRANSFORMADOR!",
        subTitle: `<p><strong>Imagine um futuro diferente para jovens em situação de vulnerabilidade social.</strong>Um futuro onde a pobreza e a violência não ditam seus destinos. Um futuro onde oportunidades e sonhos florescem.</p>
        <strong>O GAMT está construindo esse futuro.</strong>
        <strong>Mas para isso, precisamos da sua ajuda.</strong>
        <strong>Juntos, podemos construir um futuro mais justo e inclusivo para todos.</strong>
        <strong>Doe agora e faça a diferença na vida de um jovem!</strong>
        <strong>#GAMT #TransformandoFuturos #DoeEsperança</strong>
          `,
        button: " JUNTE-SE À CAUSA",
      },
      COMP00017: {
        title: "Valores",
        cards: [
          {
            alt: "Educação",
            text: "Acreditamos no poder transformador da educação como base para o desenvolvimento individual e coletivo.",
          },
          {
            alt: "Transformação",
            text: "Buscamos constantemente a transformação positiva na vida dos jovens, promovendo oportunidades que impactem significativamente seu futuro.",
          },
          {
            alt: "Cidadania",
            text: "Valorizamos e promovemos atitudes que contribuam para a construção de cidadãos responsáveis e engajados na sociedade.",
          },
          {
            alt: "Inclusão",
            text: "Trabalhamos para criar ambientes e oportunidades que promovam a inclusão de todos, independentemente de suas origens ou circunstâncias.",
          },
          {
            alt: "Impacto Social",
            text: "Buscamos gerar um impacto positivo e duradouro nas comunidades, promovendo mudanças significativas e sustentáveis.",
          },
          {
            alt: "Ética e Integridade",
            text: "Pautamos nossas ações nos mais altos padrões éticos, garantindo transparência, honestidade e integridade em todas as nossas atividades.",
          },
        ],
        subTitle:
          "No GAMT, cada jovem é uma história de sucesso em construção. Faça parte desta narrativa de impacto.",
        button: "Doe agora",
      },
      COMP00018: {
        title: "LINHA DO TEMPO DO ORGANIZAÇÃO",
        altText: "Foto de",
        cards: [
          {
            title: "1975",
            content:
              "Zélia Madureira e outros defensores da causa juvenil fundam o GAMT, inicialmente chamado de Grupo de Assistência ao Menor Trabalhador.",
          },
          {
            title: "1978",
            content:
              "Construção da primeira sede própria. Nesse período, os jovens integravam a Guarda Mirim, desempenhando funções como office boy e controle de zoneamento no centro comercial.",
          },
          {
            title: "1981",
            content:
              "Início das atividades na sede atual, em um prédio construído em terreno doado pela Prefeitura Municipal de Caçapava, conforme Lei nº 1814, de 15 de setembro de 1978.",
          },
          {
            title: "2000",
            content:
              "Com a criação da Lei da Aprendizagem (nº 10.097), que impactou o número de jovens no mercado de trabalho, o GAMT, com o apoio financeiro da Johnson & Johnson, iniciou um projeto de inclusão digital.",
          },
          {
            title: "2006",
            content:
              "Uma nova visão de execução de projetos é implementada. Surge o Ponto de Encontro, reunindo várias oficinas de formação, e é adotada a metodologia Miniempresa, da Junior Achievement.",
          },
          {
            title: "2007",
            content:
              "Pela primeira vez, o GAMT recebe recursos do Criança Esperança.",
          },
          {
            title: "2008",
            content: "Início das práticas de ensino de robótica educacional.",
          },
          {
            title: "2009",
            content:
              "Início das práticas para a formação e produção dos jovens com um estúdio de TV e rádio.",
          },
          {
            title: "2010",
            content:
              "Mudança de nome para Grupo de Assessoria e Mobilização de Talentos. Início da expansão das atividades para outros municípios por meio da escola de percussão Meninos do GAMT, conquistando o título de Ponto de Cultura.",
          },
          {
            title: "2018",
            content:
              "Retomada da relação com a formação em cultura por meio da Orquestra Jovens Urbanos. Recebimento novamente do fomento do Criança Esperança.",
          },
        ],
        appeal: "Acreditamos no poder da educação para gerar mudanças reais. ",
        button: "Doe agora",
      },
      COMP00019: {
        title: "Parceiros que apoiam nossa causa.",
        logos: [
          { alt: "Logo da empresa X" },
          { alt: "Logo da organização Y" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
          { alt: "Logo de alguém" },
        ],
      },
      COMP00024: {
        title: "CONFIRA NOSSOS DEPOIMENTOS",
        altText: "Foto de",
        cards: [
          {
            title: "Anie Hirano",
            content: `"Se você é um adolescente que participa de alguma forma dos cursos do Gamt, acredite, ele irá te trazer uma impulsão incrível, se você já passou dessa idade, acredite na instituição, incentive, colabore, pois o Gamt forma e reforma a vida de muitas pessoas!"`,
          },
          {
            title: "Kayo Henrique",
            content: `''Tenho 20 anos. Eu conheci o GAMT através de um amigo e fui alertado sobre o processo seletivo para o jovens urbanos pela minha tia. Eu me inscrevi e passei por  uma prova super simples. Chegando lá, foi inovador e bastante diferente no início, pois eu estava acostumado com os métodos de ensino das escolas públicas.''`,
          },
          {
            title: "Syang Affini",
            content: `''Meu nome é Syang e tenho 17 anos. Conheci o Jovens Urbanos através de familiares que me indicaram. Fiz uma pesquisa e achei que era um programa bom, então me inscrevi e entrei para o jovens urbanos.''`,
          },
          {
            title: "Ana Carolina Martins",
            content: `
            <p>''Meu nome é Ana Carolina, tenho 18 anos e minha experiência no GAMT foi e continua sendo incrível.No programa jovens urbanos aprendi a desenvolver algumas habilidades, como trabalhar em grupo, falar  melhor em público, criar grandes projetos,  lidar com os problemas repentinos e entregas de trabalhos imediatos. ''No programa jovens urbanos aprendi a desenvolver algumas habilidades, como trabalhar em grupo, falar  melhor em público, criar grandes projetos,  lidar com os problemas repentinos e entregas de trabalhos imediatos. ''Conheci o Programa Jovens Urbanos através de uma publicação nas redes sociais, que dizia preparar os jovens para ingressar no mercado de trabalho. Me interessei logo de cara, afinal, esse é o desejo de todos nessa faixa etária, não é mesmo? Foi então que resolvi me inscrever no começo de 2019, fiz uma provinha bem simples e consegui entrar.''</p>
            `,
          },
        ],
      },
      COMP_ALLYA: {
        title: `Faça parte do `,
        description: `
          <p>Sua doação é muito importante para nós e faz toda a diferença. O processo de <strong>doação</strong> é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente. Quer sua doação seja grande ou pequena, saiba que ela é uma prova do seu compromisso com um mundo melhor para todos. Junte-se a nós hoje na criação de um futuro onde o poder duradouro do amor prevaleça. </p>
          <p><strong>Doe todos os meses</strong> para <strong>{{NAME}}</strong> e participe do nosso <strong>Clube do Bem</strong>, uma parceria altruísta e  filantrópica entre <strong>Propago e Allya.</strong></p>
          <p>No Clube do Bem da Propago  você irá economizar dinheiro, tornar seu dia a dia mais prático e o melhor de tudo, sua economia pode virar uma doação!</p>
          <p>Você contará com uma série de <strong>descontos, vantagens e benefícios</strong> em mais de <strong>30 mil estabelecimentos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser e muito mais! </p>
        `,
        list: [
          {
            area: "Beleza e bem-estar! -",
            discount: "Até 80%",
          },
          {
            area: "Lazer -",
            discount: "Até 50% OFF",
          },
          {
            area: "Restaurantes -",
            discount: "Até 35% OFF",
          },
          {
            area: "Cursos e Faculdades -",
            discount: "Até 80%",
          },
        ],
        bottomDescription: `Viu como sua economia pode gerar o bem para todo o ecossistema?`,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo ajudar pessoas do bem  a manterem um padrão de vida mais econômico de forma inteligente, levando consigo a filantropia!`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo {{NAME}}.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o {{NAME}} emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo {{NAME}} é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização do escritório de Grupo de Assessorias e Mobilização de Talentos - GAMT em {{CITY}}",
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - Grupo de Assessorias e Mobilização de Talentos - GAMT ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "A Grupo de Assessorias e Mobilização de Talentos - GAMT se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations["pt-BR"].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Towards the Bright Future GAMT - Education, Inclusion and Transformative Opportunities",
      },
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make a donation",
      },
      HEAD00002: {
        title: "GRUPO DE ASSESSORIA E MOBILIZAÇÃO DE TALENTOS  GAMT",
        subtitle:
          "We turn dreams into opportunities, shaping the future through education and inclusion.",
        button: "Learn more",
      },
      COMP00004: {
        title: "OUR NUMBERS",
        cards: [
          {
            top: "OVER",
            span: "30",
            bottom: "PROJECTS COMPLETED",
          },
          {
            top: "OVER",
            span: "300",
            bottom: "BENEFITED BUSINESSES",
          },
          {
            top: "OVER",
            span: "4,500",
            bottom: "YOUTH INSERTED INTO THE JOB MARKET",
          },
        ],
        button: "BE PART OF THE TRANSFORMATION: DONATE!",
      },
      COMP00005: {
        title: "INSTITUTE GUIDELINES",
        subTitle: "",
        cards: [
          {
            title: "Mission",
            text: "To offer personal and professional development opportunities for youth to enter the job market, through education and citizenship empowerment.",
          },
          {
            title: "Vision",
            text: "To be the national reference in the social transformation of youth.",
          },
        ],
        button: "DONATE NOW.",
      },
      COMP00006: {
        title: "",
        cards: [
          {
            title: "<strong>Discover the Potential at GAMT LAB!</strong>",
            text: `
            <p>GAMT LAB, where we turn children and teenagers aged 6 to 15 into true protagonists of their own learning! Here, activities go beyond traditional classrooms, providing hands-on experiences and stimulating the development of essential skills for the world of work.</p>
            <p>In our practical classes, we dive into the world of technology, creativity, and teamwork. We challenge participants to think outside the box, solve complex problems, and explore new forms of creative expression. The goal? Prepare our students for the jobs of the future, empowering them to learn continuously.</p>
            `,
          },
          {
            title: "<strong>Children and Youth as Protagonists in the Technology World</strong>",
            text:
              '"In the GAMT Fab Lab, participants have the unique opportunity to create prototypes using recyclable materials, 3D printers, and other amazing tools. Learning programming becomes an engaging journey, using visual and intuitive languages to develop fundamental skills in an accessible and fun way."',
          },
          {
            title:
              "<strong>Opportunity Map: Preparation for the Job Market</strong>",
            text:
              `
              <p>Youths aged 16 to 19 have the opportunity to participate in the Opportunity Map Program, a hybrid and dynamic training that puts them as protagonists of their own professional journey. Upon completing the course, the young person will become part of our Talent Pool, opening doors to learning and internship opportunities with partner companies.</p>`,
          },
        ],
        appeal: "Inspiring education, transformative inclusion.",
        button: "DONATE NOW AND MAKE A DIFFERENCE!",
      },

      COMP00008: {
        title: "BE THE BRIDGE TO A TRANSFORMATIVE FUTURE!",
        subTitle: `<p><strong>Imagine a different future for young people in situations of social vulnerability.</strong> A future where poverty and violence do not dictate their destinies. A future where opportunities and dreams flourish.</p>
        <strong>GAMT is building that future.</strong>
        <strong>But for that, we need your help.</strong>
        <strong>Together, we can build a fairer and more inclusive future for all.</strong>
        <strong>Donate now and make a difference in a young person's life!</strong>
        <strong>#GAMT #TransformingFutures #DonateHope</strong>
          `,
        button: "JOIN THE CAUSE",
      },
      COMP00017: {
        title: "Values",
        cards: [
          {
            alt: "Education",
            text: "We believe in the transformative power of education as the basis for individual and collective development.",
          },
          {
            alt: "Transformation",
            text: "We constantly seek positive transformation in the lives of young people, promoting opportunities that significantly impact their future.",
          },
          {
            alt: "Citizenship",
            text: "We value and promote attitudes that contribute to the construction of responsible and engaged citizens in society.",
          },
          {
            alt: "Inclusion",
            text: "We work to create environments and opportunities that promote the inclusion of all, regardless of their backgrounds or circumstances.",
          },
          {
            alt: "Social Impact",
            text: "We seek to generate a positive and lasting impact on communities, promoting significant and sustainable changes.",
          },
          {
            alt: "Ethics and Integrity",
            text: "We guide our actions by the highest ethical standards, ensuring transparency, honesty, and integrity in all our activities.",
          },
        ],
        subTitle:
          "At GAMT, each young person is a success story in the making. Be part of this impactful narrative.",
        button: "Donate now",
      },
      COMP00018: {
        title: "INSTITUTE TIMELINE",
        altText: "Photo of",
        cards: [
          {
            title: "1975",
            content:
              "Zélia Madureira and other advocates for youth founded GAMT, initially called the Group for Assistance to Working Minors.",
          },
          {
            title: "1978",
            content:
              "Construction of the first own headquarters. At that time, young people were part of the Guarda Mirim, performing functions such as office boy and zoning control in the commercial center.",
          },
          {
            title: "1981",
            content:
              "Start of activities in the current headquarters, in a building built on land donated by the Municipal Government of Caçapava, according to Law No. 1814, of September 15, 1978.",
          },
          {
            title: "2000",
            content:
              "With the creation of the Apprenticeship Law (No. 10,097), which impacted the number of young people in the job market, GAMT, with financial support from Johnson & Johnson, started a digital inclusion project.",
          },
          {
            title: "2006",
            content:
              "A new project execution vision is implemented. The Meeting Point emerges, bringing together various training workshops, and the Mini-Company methodology from Junior Achievement is adopted.",
          },
          {
            title: "2007",
            content:
              "For the first time, GAMT receives funds from Criança Esperança.",
          },
          {
            title: "2008",
            content: "Start of educational robotics teaching practices.",
          },
          {
            title: "2009",
            content:
              "Start of practices for the training and production of young people with a TV and radio studio.",
          },
          {
            title: "2010",
            content:
              "Name change to Talent Advisory and Mobilization Group. Start of activities expansion to other municipalities through the Meninos do GAMT percussion school, earning the title of Cultural Point.",
          },
          {
            title: "2018",
            content:
              "Resumption of the relationship with cultural training through the Young Urban Orchestra. Receiving funding again from Criança Esperança.",
          },
        ],
        appeal: "We believe in the power of education to generate real changes.",
        button: "Donate now",
      },
      COMP00019: {
        title: "Partners supporting our cause.",
        logos: [
          { alt: "Logo of company X" },
          { alt: "Logo of organization Y" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
          { alt: "Someone's logo" },
        ],
      },
      COMP00024: {
        title: "DEP",
        altText: "Photo of",
        cards: [
          {
            title: "Anie Hirano",
            content: `"If you are a teenager who in any way participates in GAMT's courses, believe me, it will bring you an incredible boost, if you have passed this age, believe in the institution, encourage, collaborate, as GAMT trains and reforms the lives of many people!"`,
          },
          {
            title: "Kayo Henrique",
            content: `''I'm 20 years old. I met GAMT through a friend and was alerted about the selection process for young urbanites by my aunt. I applied and passed a very simple test. When I got there, it was innovative and quite different at first, because I was used to the teaching methods of public schools.''`,
          },
          {
            title: "Syang Affini",
            content: `''My name is Syang and I'm 17 years old. I got to know Jovens Urbanos through relatives who recommended it to me. I did some research and thought it was a good program, so I signed up and joined the young urbanites.''`,
          },
          {
            title: "Ana Carolina Martins",
            content: `
            <p>''My name is Ana Carolina, I'm 18 years old and my experience at GAMT was and continues to be incredible. In the young urbanites program, I learned to develop some skills, such as working in groups, speaking better in public, creating big projects, dealing with sudden problems and immediate work deliveries.''I met the Young Urbanites Program through a post on social media, which said it prepares young people to enter the job market. I was interested right away, after all, that's everyone's desire at this age, isn't it? So I decided to sign up at the beginning of 2019, took a very simple test and got in.''</p>
            `,
          },
        ],
      },
      COMP_ALLYA: {
        title: "Join the ",
        description: `
          <p>Your donation is very important to us and makes all the difference. The process of <strong>donation</strong> is simple, secure, and easy. The donated money is reverted to our social and philanthropic actions. You can donate once or every month, recurrently. Whether your donation is big or small, know that it is proof of your commitment to a better world for everyone. Join us today in creating a future where the lasting power of love prevails.</p>
          <p><strong>Donate every month</strong> to <strong>Grupo de Assessorias e Mobilização de Talentos - GAMT</strong> and participate in our <strong>Clube do Bem</strong> (Club of Good), an altruistic and philanthropic partnership between <strong>Propago and Allya.</strong></p>
          <p>In Propago's Clube do Bem you will save money, make your day-to-day more practical and best of all, your savings can turn into a donation!</p>
          <p>You will have a series of <strong>discounts, advantages, and benefits</strong> in more than <strong>30 thousand establishments</strong> such as: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser and much more!</p>
        `,
        list: [
          {
            area: "Beauty and well-being! -",
            discount: "Up to 80% OFF",
          },
          {
            area: "Leisure - ",
            discount: "Up to 50% OFF",
          },
          {
            area: "Restaurants -",
            discount: "Up to 35% OFF",
          },
          {
            area: "Courses and Undergraduations -",
            discount: "Up to 80% OFF",
          },
        ],
        bottomDescription: `<p>See how your economy can do good for the whole ecosystem?</p>`,
        text: `<strong style="color: #F73D6A;">Allya</strong> aims to aims to help good people maintain a more economical standard of living in an intelligent way, bringing philanthropy with it!`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change someone's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title:
          "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does Grupo de Assessorias e Mobilização de Talentos - GAMT accept donations in kind",
            subTitle: "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle:
              "You can make your donation, whether punctual or recurring by credit card.",
          },
          {
            title: "Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both Grupo de Assessorias e Mobilização de Talentos - GAMT and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle:
              "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle:
          "Localization of Grupo de Assessorias e Mobilização de Talentos - GAMT's office in Ribeirão Preto - SP (Brazil)",
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - Grupo de Assessorias e Mobilização de Talentos - GAMT ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "Grupo de Assessorias e Mobilização de Talentos - GAMT worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    },
  },
  es: {
    translations: {
      Page: {
        title: "Hacia un futuro brillante GAMT - Educación, inclusión y oportunidades transformadoras",
      },
      ButtonFloating: {
        button: "Dona ahora",
        buttonBottom: "Haz una donácion",
      },
      HEAD00002: {
        title: "GRUPO DE ASESORAMIENTO Y MOVILIZACIÓN DE TALENTOS - GAMT",
        subtitle:
          "Convertimos sueños en oportunidades, dando forma al futuro a través de la educación y la inclusión.",
        button: "Más información",
      },
      COMP00004: {
        title: "NUESTROS NÚMEROS",
        cards: [
          {
            top: "MÁS DE",
            span: "30",
            bottom: "PROYECTOS REALIZADOS",
          },
          {
            top: "MÁS DE",
            span: "300",
            bottom: "EMPRESAS BENEFICIADAS",
          },
          {
            top: "MÁS DE",
            span: "4.500",
            bottom: "JOVENES INSERTADOS EN EL MERCADO",
          },
        ],
        button: "¡SÉ PARTE DE LA TRANSFORMACIÓN: DONA!",
      },
      COMP00005: {
        title: "DIRECTRICES DEL ORGANIZAÇÃO",
        subTitle: "",
        cards: [
          {
            title: "Misión",
            text: "Ofrecer oportunidades de desarrollo personal y profesional para la inserción de jóvenes en el mercado laboral, a través de la educación y la valorización de la ciudadanía.",
          },
          {
            title: "Visión",
            text: "Ser la referencia nacional en la transformación social de los jóvenes.",
          },
        ],
        button: "¡DONA AHORA!",
      },
      COMP00006: {
        title: "",
        cards: [
          {
            title: "<strong>¡Conoce el Potencial en GAMT LAB!</strong>",
            text: `
            <p>GAMT LAB, donde convertimos a niños y adolescentes de 6 a 15 años en verdaderos protagonistas de su propio aprendizaje. Aquí, las actividades van más allá de las aulas tradicionales, brindando experiencias prácticas y estimulando el desarrollo de habilidades esenciales para el mundo laboral.</p>
            <p>En nuestras clases prácticas, nos sumergimos en el universo de la tecnología, la creatividad y el trabajo en equipo. Desafiamos a los participantes a pensar fuera de la caja, a resolver problemas complejos y a explorar nuevas formas de expresión creativa. ¿El objetivo? Preparar a nuestros estudiantes para los trabajos del futuro, capacitándolos para aprender siempre.</p>
            `,
          },
          {
            title: "<strong>Niños y jóvenes como Protagonistas en el mundo de la Tecnología</strong>",
            text:
              '"En Fab Lab GAMT, los participantes tienen la oportunidad única de crear prototipos utilizando materiales reciclables, impresoras 3D y otras herramientas increíbles. Aprender programación se convierte en un viaje cautivador, utilizando lenguajes visuales e intuitivos para desarrollar habilidades fundamentales de manera accesible y divertida."',
          },
          {
            title:
              "<strong>Mapa de Oportunidades: Preparación para el Mercado Laboral</strong>",
            text:
              `
              <p>Los jóvenes de 16 a 19 años tienen la oportunidad de participar en el Programa Mapa de Oportunidades, una formación híbrida y dinámica que los coloca como protagonistas de su propia jornada profesional. Al completar el curso, el joven se convertirá en parte de nuestro Banco de Talentos, abriendo puertas a oportunidades de aprendizaje y pasantías en empresas asociadas.</p>`,
          },
        ],
        appeal: "Educación que inspira, inclusión que transforma.",
        button: "¡DONA AHORA Y HAZ LA DIFERENCIA!",
      },
      COMP00008: {
        title: "¡SÉ EL PUENTE HACIA UN FUTURO TRANSFORMADOR!",
        subTitle: `<p><strong>Imagina un futuro diferente para jóvenes en situación de vulnerabilidad social.</strong> Un futuro donde la pobreza y la violencia no dicten sus destinos. Un futuro donde florezcan las oportunidades y los sueños.</p>
        <strong>GAMT está construyendo ese futuro.</strong>
        <strong>Pero para lograrlo, necesitamos tu ayuda.</strong>
        <strong>Juntos, podemos construir un futuro más justo e inclusivo para todos.</strong>
        <strong>¡Dona ahora y marca la diferencia en la vida de un joven!</strong>
        <strong>#GAMT #TransformandoFuturos #DonaEsperanza</strong>
        `,
        button: "ÚNETE A LA CAUSA",
      },

      COMP00017: {
        title: "Valores",
        cards: [
          {
            alt: "Educación",
            text: "Creemos en el poder transformador de la educación como base para el desarrollo individual y colectivo.",
          },
          {
            alt: "Transformación",
            text: "Buscamos constantemente la transformación positiva en la vida de los jóvenes, promoviendo oportunidades que impacten significativamente su futuro.",
          },
          {
            alt: "Ciudadanía",
            text: "Valoramos y promovemos actitudes que contribuyan a la construcción de ciudadanos responsables y comprometidos con la sociedad.",
          },
          {
            alt: "Inclusión",
            text: "Trabajamos para crear ambientes y oportunidades que promuevan la inclusión de todos, independientemente de sus orígenes o circunstancias.",
          },
          {
            alt: "Impacto Social",
            text: "Buscamos generar un impacto positivo y duradero en las comunidades, promoviendo cambios significativos y sostenibles.",
          },
          {
            alt: "Ética e Integridad",
            text: "Guiamos nuestras acciones por los más altos estándares éticos, garantizando transparencia, honestidad e integridad en todas nuestras actividades.",
          },
        ],
        subTitle:
          "En GAMT, cada joven es una historia de éxito en construcción. Sé parte de esta narrativa de impacto.",
        button: "Dona ahora",
      },
      COMP00018: {
        title: "LÍNEA DEL TIEMPO DEL ORGANIZAÇÃO",
        altText: "Foto de",
        cards: [
          {
            title: "1975",
            content:
              "Zélia Madureira y otros defensores de la causa juvenil fundan GAMT, inicialmente llamado Grupo de Asistencia al Menor Trabajador.",
          },
          {
            title: "1978",
            content:
              "Construcción de la primera sede propia. En este período, los jóvenes formaban parte de la Guardia Juvenil, desempeñando funciones como mensajeros y control de zonificación en el centro comercial.",
          },
          {
            title: "1981",
            content:
              "Inicio de las actividades en la sede actual, en un edificio construido en terreno donado por la Municipalidad de Caçapava, según la Ley N° 1814, de 15 de septiembre de 1978.",
          },
          {
            title: "2000",
            content:
              "Con la creación de la Ley de Aprendizaje (N° 10.097), que impactó en el número de jóvenes en el mercado laboral, GAMT, con el apoyo financiero de Johnson & Johnson, inició un proyecto de inclusión digital.",
          },
          {
            title: "2006",
            content:
              "Se implementa una nueva visión de ejecución de proyectos. Surge el Punto de Encuentro, reuniendo varios talleres de formación, y se adopta la metodología Miniempresa de Junior Achievement.",
          },
          {
            title: "2007",
            content:
              "Por primera vez, GAMT recibe fondos del Criança Esperança.",
          },
          {
            title: "2008",
            content: "Inicio de prácticas educativas de robótica.",
          },
          {
            title: "2009",
            content:
              "Inicio de prácticas para la formación y producción de jóvenes con un estudio de televisión y radio.",
          },
          {
            title: "2010",
            content:
              "Cambio de nombre a Grupo de Asesoramiento y Movilización de Talentos. Inicio de la expansión de actividades a otros municipios a través de la escuela de percusión Meninos do GAMT, obteniendo el título de Punto de Cultura.",
          },
          {
            title: "2018",
            content:
              "Reanudación de la relación con la formación cultural a través de la Orquesta Jóvenes Urbanos. Recibiendo nuevamente financiamiento del Criança Esperança.",
          },
        ],
        appeal: "Creemos en el poder de la educación para generar cambios reales.",
        button: "Dona ahora",
      },
      COMP00019: {
        title: "Socios que apoyan nuestra causa.",
        logos: [
          { alt: "Logotipo de la empresa X" },
          { alt: "Logotipo de la organización Y" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
          { alt: "Logotipo de alguien" },
        ],
      },
      COMP00024: {
        title: "DEP",
        altText: "Foto de",
        cards: [
          {
            title: "Anie Hirano",
            content: `"Si eres un adolescente que participa de alguna manera en los cursos de GAMT, créeme, te traerá un impulso increíble, si has pasado esta edad, cree en la institución, anima, colabora, ya que GAMT forma y reforma la vida de muchas personas!"`,
          },
          {
            title: "Kayo Henrique",
            content: `''Tengo 20 años. Conocí GAMT a través de un amigo y fui alertado sobre el proceso de selección para jóvenes urbanos por mi tía. Me inscribí y pasé una prueba muy simple. Cuando llegué allí, fue innovador y bastante diferente al principio, porque estaba acostumbrado a los métodos de enseñanza de las escuelas públicas.''`,
          },
          {
            title: "Syang Affini",
            content: `''Mi nombre es Syang y tengo 17 años. Conocí Jovens Urbanos a través de familiares que me lo recomendaron. Investigué un poco y pensé que era un buen programa, así que me inscribí y me uní a los jóvenes urbanos.''`,
          },
          {
            title: "Ana Carolina Martins",
            content: `
            <p>''Mi nombre es Ana Carolina, tengo 18 años y mi experiencia en GAMT fue y sigue siendo increíble. En el programa de jóvenes urbanos, aprendí a desarrollar algunas habilidades, como trabajar en grupo, hablar mejor en público, crear grandes proyectos, lidiar con problemas repentinos y entregas de trabajo inmediatas.''Conocí el Programa Jovens Urbanos a través de una publicación en redes sociales, que decía que preparaba a los jóvenes para ingresar al mercado laboral. Me interesó de inmediato, después de todo, ¿no es ese el deseo de todos en esta edad? Fue entonces cuando decidí inscribirme a principios de 2019, hice una prueba muy simple y conseguí entrar.''</p>
            `,
          },
        ],
      },
      COMP_ALLYA: {
        title: "¡Venga y forme parte de nuestro ecosistema del bien!",
        description: `
          <p>Su donación es muy importante para nosotros y marca la diferencia. El proceso de donación es sencillo, seguro y fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Puede donar una vez o cada mes de forma periódica. Tanto si su donación es grande como pequeña, sepa que es una prueba de su compromiso con un mundo mejor para todos. Únase hoy a nosotros para crear un futuro en el que prevalezca el poder perdurable del amor.</p>
          <p><strong>Dona todos los meses a Grupo de Assessorias e Mobilização de Talentos - GAMT</strong> y participa en nuestro <strong>Clube do Bem</strong>, una asociación altruista y filantrópica entre <strong>Propago y Allya.</strong></p>
          <p>Con el Clube do Bem de Propago ahorrarás dinero, harás tu día a día más práctico y lo mejor de todo, ¡tu ahorro puede convertirse en donación!</p>
          <p>¡Disfrutarás de una <strong>serie de descuentos</strong>, ventajas y beneficios en <strong>más de 30.000 establecimientos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser y muchos más!</p>
        `,
        list: [
          {
            area: "Belleza y bienestar -",
            discount: "Hasta el 80% de descuento",
          },
          {
            area: "Ocio -",
            discount: "Hasta el 50% de descuento",
          },
          {
            area: "Restaurantes -",
            discount: "Hasta el 35% de descuento",
          },
          {
            area: "Cursos y Universidades -",
            discount: "Hasta el 80% de descuento",
          },
        ],
        bottomDescription: `<p>¿Ves cómo tu economía puede hacer bien a todo el ecosistema?</p>`,
        text: `El objetivo de <strong style="color: #F73D6A;">Allya</strong> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title:
              "¿Grupo de Assessorias e Mobilização de Talentos - GAMT acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title:
              "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title:
              "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para Grupo de Assessorias e Mobilização de Talentos - GAMT como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title: "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de la oficina de Grupo de Assessorias e Mobilização de Talentos - GAMT",
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - Grupo de Assessorias e Mobilização de Talentos - GAMT® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "Grupo de Assessorias e Mobilização de Talentos - GAMT preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    },
  },
};

export default i18n;
