import { useState, lazy, Suspense } from "react";

// Components
import SectionCookies from "./components/SectionCookies";
import ButtonDonateNow from "./components/ButtonDonateNow";
import HEAD00002 from "./components/Wireframes/HEAD00002";


// Translations
import { startI18N } from "./i18n";
import i18nTranslations from "./whitelabels/gamt/i18n";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy Loaded Components
const COMP00004 = lazy(() => import("./components/Wireframes/COMP00004"));
const COMP00005 = lazy(() => import("./components/Wireframes/COMP00005"));
const COMP00006 = lazy(() => import("./components/Wireframes/COMP00006"));
const COMP00008 = lazy(() => import("./components/Wireframes/COMP00008"));
const COMP00017 = lazy(() => import("./components/Wireframes/COMP00017"));
const COMP00018 = lazy(() => import("./components/Wireframes/COMP00018"));
const COMP00019 = lazy(() => import("./components/Wireframes/COMP00019"));
const COMP00024 = lazy(() => import("./components/Wireframes/COMP00024"));
const COMP_ALLYA = lazy(() => import("./components/Wireframes/COMP_ALLYA"));
const CHEC00001 = lazy(() => import("./components/Wireframes/CHEC00001"));
const FAQS00001 = lazy(() => import("./components/Wireframes/FAQS00001"));
const COMP_GEOL = lazy(() => import("./components/Wireframes/COMP_GEOL"));
const FOOT00001 = lazy(() => import("./components/Wireframes/FOOT00001"));

const App = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  let dirName = 'gamt';

  startI18N(i18nTranslations);

  return (
    <>
      <div className="lp-app notranslate">
        <HEAD00002 dirName={dirName} />
        <ButtonDonateNow
          primaryColor="#0086CD"
          secondaryColor="#FFFFFF"
          borderFixedColor="#FFFFFF"
          borderFixedHoverColor="#0086CD"
        />
        {SectionCookies != '' && acceptedCookies === false && (
          <SectionCookies onAccept={() => setAcceptedCookies(true)} />
        )}
        <Suspense>
          <COMP00008 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00005 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00017 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00006 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00004 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00018 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00024 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00019 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP_ALLYA />
        </Suspense>
        <Suspense>
          <CHEC00001 />
        </Suspense>
        <Suspense>
          <FAQS00001 />
        </Suspense>
        <Suspense>
          <COMP_GEOL url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.729536366863!2d-45.70402932452908!3d-23.106994645057284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc516246940001%3A0xb4f0a628eb6c6d45!2sGAMT%20-%20Educa%C3%A7%C3%A3o%20e%20Cidadania!5e0!3m2!1spt-BR!2sbr!4v1707324194325!5m2!1spt-BR!2sbr" />
        </Suspense>
        <Suspense>
          <FOOT00001 />
        </Suspense>
      </div>
    </>
  );
}

export default App;